<template>
    <div class="body">
        <div class="problem-wrapper">
            <ul class="problem-list-wrapper">
                <li>
                    <div class="rank">1</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>什么是一碗福州城市美食卡（简称：碗卡）？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>由视通传媒联合《福州晚报》、《福州日报》、福州新闻网、福州有意思、一碗福州碗友会等媒体共同推出的一张被称为“一把打开福州顶级美食的金钥匙”的美食卡。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">2</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>如何购买碗卡？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>用户可登陆一碗福州商场购卡专区购买一碗福州·城市美食卡</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">3</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>碗卡的权益有哪些？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>持卡可参与一年两季顶级餐厅周活动，每周二热门餐厅HOT50五折日活动、一碗之选、热门商圈、60家餐厅日常会员折扣等固定栏目活动。同时还可享小龙虾周、自助餐周等限定栏目活动。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">4</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>什么是顶级餐厅周？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>每年分为春、秋两季进行顶级餐厅周活动。活动将由80家餐厅提供顶级菜单，碗友可以以午餐88元，晚餐138元购买，最终于年末通过评选选出20家顶级餐厅。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">5</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>顶级餐厅有用餐时间限制吗？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>有，顶级餐厅用餐时间在顶级餐厅周范围内，根据预售时选择的用餐时间到店用餐（具体午/晚餐用餐时间以餐厅食集营业时间为准）。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">6</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>顶级餐厅用餐是否需要预约？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>需要预约，顶级餐厅周活动用餐前需提前1天与商家预约。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">7</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>是否可以打包？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>不可以，套餐仅限堂食，不得外带打包，用餐结束后未吃完可以打包。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">8</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>什么是热门餐厅？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>一碗福州于每周二举行热门餐厅HOT50五折日活动，活动当天碗友可凭一碗福州·城市美食卡，到指定门店消费享受全场5折（酒水除外）</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">9</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>如何享受热门餐厅HOT50周二五折日活动？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>碗友可于互动当日到指定门店，点击商城上相应商户链接获取二维码，商户扫描二维码进行核销。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">10</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>热门餐厅需要预约吗？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>热门餐厅原则上无需预约，但碗友们可以选择提前预约，以减少等候时间。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">11</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>热门餐厅是否可以外带打包？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>不可以，热门餐厅原则上不可以打包，必须堂食。但用餐结束后没吃完的食物可以打包。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">12</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>一碗之选开售的时间是什么时候？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>一碗之选栏目每周三、周日晚20:00不定期开售，敬请关注。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">13</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>一碗之选套餐支持外带打包吗？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>不支持，一碗之选套餐仅限堂食，除用餐结束未吃完的餐食外，不支持打包。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">14</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>一碗之选套餐可以和商户其他优惠共享吗？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>不可以，一碗之选套餐不可与商户其他优惠同享。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank">15</div>
                    <div class="content">
                        <div class="Q"><em>Q</em><p>套餐超过使用期限可以退款吗？</p></div>
                        <div class="A">
                            <em>A</em>
                            <div>
                                <p>不可以，一碗之选套餐必须在使用期限内使用完毕，超过使用期限不予退款。</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="rank" @click="test">16...</div>
                    <div class="content">
                        2
                        {{testData}}
                    </div>
                </li>
            </ul>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import FloatNav from '../../components/common/FloatNav'
  import { getLocalStorage } from '../../utils/localStorage'
  export default {
    name: 'Problem',
    components: { FloatNav },
    data () {
      return {
        testData: ''
      }
    },
    methods: {
      test: function () {
        window.location.href = 'https://club.seetop.net.cn?k=655'
      }
    },
    mounted () {
      this.testData = getLocalStorage('currentBrowser')
    }
  }
</script>

<style scoped>
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .body{
        background: #f7f7f7;
        color: #626262;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .problem-wrapper{
        background: #fff;
    }
    .problem-wrapper .problem-list-wrapper {
        padding: 1rem 0;
    }
    .problem-wrapper .problem-list-wrapper li {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem 1rem;
    }
    .problem-wrapper .problem-list-wrapper li .rank {
        color: #df545b;
        font-size: 2.5rem;
        font-style: italic;
        font-weight: 300;
        /*font-family: cursive;*/
        margin-right: 1.5rem;
        line-height: 2.5rem;
        width: 10%;
        text-align: center;
    }
    .problem-wrapper .problem-list-wrapper li .content{
        width: 90%;
    }
    .problem-wrapper .problem-list-wrapper li .content .Q {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 0.5rem;
    }
    .problem-wrapper .problem-list-wrapper li .content .Q em {
        background: #d3aa6c;
        color: #fff;
        border-radius: 5px;
        padding: 0 0.3rem;
        font-size: 0.7rem;
        margin-right: 0.5rem;
        margin-top: 0.1rem;
    }
    .problem-wrapper .problem-list-wrapper li .content .Q p {
        color: #d3aa6c;
        font-size: 0.9rem;
    }
    .problem-wrapper .problem-list-wrapper li .content .A {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .problem-wrapper .problem-list-wrapper li .content .A em {
        background: #333;
        color: #fff;
        border-radius: 5px;
        padding: 0 0.3rem;
        font-size: 0.7rem;
        margin-right: 0.5rem;
    }
    .problem-wrapper .problem-list-wrapper li .content .A p {
        color: #888;
        font-size: 0.8rem;
    }
    .problem-wrapper footer {
        height: 4rem;
        line-height: normal;
    }
</style>
